.verify-code-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .verify-code {
    width: 300px;
    padding: 20px;
    background-color: white;
    input {
      text-align: center;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type='number'] {
        -moz-appearance: textfield;
      }
    }
    .resend-code {
      width: 100%;
      justify-content: flex-end;
      display: flex;
      .ant-btn {
        padding: 5px 0px;
      }
      .ant-btn-link span {
        padding: 5px 0px;
      }
    }
    // &::before {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   content: ' ';
    //   width: 100%;
    //   height: 100%;
    //   z-index: -1;

    //   background-image: url(../../../assets/images/landing/splash.jpg);
    //   background-size: cover;
    //   background-repeat: no-repeat;
    //   background-position: center center;
    // }
  }
}

@hd-screen-down: ~'(max-width:1280px)';@wide-screen-down: ~'(max-width:1199px)';@desktop-down: ~'(max-width:991px)';@tablet-down: ~'(max-width:767px)';@phone-down: ~'(max-width:480px)';@small-phone-down: ~'(max-width:320px)';@primary-color: rgba(19, 167, 61, 100);@info-color: rgba(41, 74, 147, 100);@danger-color: #a71d13;@layout-body-background: #fff;@border-radius-base: 4px;@light-gray-color: #f9f9fb;@orange-color: #fbae17;@purple-color: rgba(57, 26, 131, 1);@steel-color: rgba(117, 137, 141, 1);@light-blue-color: rgba(51, 156, 215, 1);