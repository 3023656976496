@import 'variables.less';
@import 'mixins.less';

#social-wrapper {
  .flex-row();
  .flex-centered();

  #facebook {
    color: #ffffff;
    background-color: #3b5998;
    border-color: #3b5998;

    span { margin-left: 6px; }
  }

  #google {
    color: #ffffff;
    background-color: #dd4b39;
    border-color: #dd4b39;
    margin-left: 10px;

    span { margin-left: 6px; }
  }
}
@hd-screen-down: ~'(max-width:1280px)';@wide-screen-down: ~'(max-width:1199px)';@desktop-down: ~'(max-width:991px)';@tablet-down: ~'(max-width:767px)';@phone-down: ~'(max-width:480px)';@small-phone-down: ~'(max-width:320px)';@primary-color: rgba(19, 167, 61, 100);@info-color: rgba(41, 74, 147, 100);@danger-color: #a71d13;@layout-body-background: #fff;@border-radius-base: 4px;@light-gray-color: #f9f9fb;@orange-color: #fbae17;@purple-color: rgba(57, 26, 131, 1);@steel-color: rgba(117, 137, 141, 1);@light-blue-color: rgba(51, 156, 215, 1);