@import 'variables.less';
@import 'mixins.less';

.job-match-item {
  .flex-row();

  background-color: #fff;
  border-radius: @border-radius-base;
  border: 1px solid rgba(0, 0, 0, 0.04);

  flex: 1;
  padding: 1rem;

  .details-container {
    .flex-column();

    flex: 1;
    height: 100%;
    margin-left: 2rem;

    .title-container {
      .flex-row();

      .title-details {
        .flex-column();

        flex: 1;

        h4.ant-typography {
          display: inline-block;
          margin-bottom: 2px;
          text-decoration: underline;
          text-decoration-style: dashed;
          text-underline-offset: 2px;
          cursor: pointer;

          svg {
            color: @info-color;
            cursor: pointer;
            margin-left: 6px;
          }
        }

        a {
          color: @info-color;
        }
      }

      .favorite-button {
        color: rgba(0, 0, 0, 0.5);

        &.checked {
          color: @primary-color;
        }
        
        &:hover {
          color: @primary-color;
        }
      }

      .super-button {
        &.checked {
          color: @primary-color;
        }

        &:hover {
          color: @primary-color;
        }
      }
    }

    .ant-divider {
      margin: 0;
    }

    svg {
      width: 14px;
      
      &.fa-check {
        color: @primary-color;
      }

      &.fa-xmark {
        color: @danger-color;
      }
    }
  }

  &.candidate {
    .details-container {
      .title-container {
        .title-details {
          h4.ant-typography { 
            text-decoration: none; 
            cursor: default;
          }
        }
      }
    }
  }
}

.job-popover-content {
  .flex-column();
  max-width: 280px;

  .small-details {
    .flex-row();

    .column {
      .flex-column();
      flex: 1;

      > div {
        white-space: nowrap;
        margin-top: 2px;

        &:first-child {
          margin-top: 0px;
        }
      }

      &:first-child {
        margin-right: 1rem;
      }
    }
  }

  .large-details {
    .flex-column();
    flex: 1;

    .header {
      margin-top: 2px;
    }
  }

  .header {
    color: @info-color;
    font-weight: 500;
  }
}

.ant-popover-message {
  .flex-row();
  align-items: center;
  
  .ant-popover-message-title {
    padding-left: 12px;
  }

  svg {
    color: @info-color;
  }
}
@hd-screen-down: ~'(max-width:1280px)';@wide-screen-down: ~'(max-width:1199px)';@desktop-down: ~'(max-width:991px)';@tablet-down: ~'(max-width:767px)';@phone-down: ~'(max-width:480px)';@small-phone-down: ~'(max-width:320px)';@primary-color: rgba(19, 167, 61, 100);@info-color: rgba(41, 74, 147, 100);@danger-color: #a71d13;@layout-body-background: #fff;@border-radius-base: 4px;@light-gray-color: #f9f9fb;@orange-color: #fbae17;@purple-color: rgba(57, 26, 131, 1);@steel-color: rgba(117, 137, 141, 1);@light-blue-color: rgba(51, 156, 215, 1);