@import 'variables.less';
@import 'mixins.less';

.plan-selector {
  background-color: transparent;
  border-radius: 4px;

  .plan-container {
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border: 1px solid transparent;

    -webkit-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);

    .title-bar {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      text-align: center;
      color: #fff;
      padding: 0.5rem;
      font-size: 14px;
      font-weight: 500;

      h4 { margin: 0; }
    }

    .plan-content {
      padding: 0.5rem;
      padding-top: 0;
      display: flex;
      flex-direction: column;

      .section-header {
        margin-top: 0.5rem;
        font-size: 12px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        text-align: center;
      }

      .section {
        font-size: 14px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        text-align: center;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);

        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }
      }
    }

    &.blue {
      .title-bar { background-color: @info-color; }
      .price { color: @info-color; }
      + .ant-ribbon { background-color: @light-blue-color; }
    }

    &.green {
      .title-bar { background-color: @primary-color; }
      .price { color: @primary-color; }
      + .ant-ribbon { background-color: @info-color; }
    }

    &.orange {
      .title-bar { background-color: @orange-color; }
      .price { color: @orange-color; }
      + .ant-ribbon { background-color: @primary-color; }
    }

    &.purple {
      .title-bar { background-color: @purple-color; }
      .price { color: @purple-color; }
      + .ant-ribbon { background-color: @orange-color; }
    }

    &.steel {
      .title-bar { background-color: @steel-color; }
      .price { color: @steel-color; }
      + .ant-ribbon { background-color: @purple-color; }
    }

    &.light-blue {
      .title-bar { background-color: @light-blue-color; }
      .price { color: @light-blue-color; }
      + .ant-ribbon { background-color: @steel-color; }
    }
  }

  &.disabled {
    opacity: 0.5;
    
    .plan-container {
      cursor: default;
    }
  }

  &:not(.disabled) {
    &.selected,
    &:hover {
      .plan-container {
        border-color: @info-color;
        transform: translateY(-3px) scale(1.0125, 1.0125);
  
        -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  
        + .ant-ribbon {
          top: 4px;
        }
      }
    }
  }
}
@hd-screen-down: ~'(max-width:1280px)';@wide-screen-down: ~'(max-width:1199px)';@desktop-down: ~'(max-width:991px)';@tablet-down: ~'(max-width:767px)';@phone-down: ~'(max-width:480px)';@small-phone-down: ~'(max-width:320px)';@primary-color: rgba(19, 167, 61, 100);@info-color: rgba(41, 74, 147, 100);@danger-color: #a71d13;@layout-body-background: #fff;@border-radius-base: 4px;@light-gray-color: #f9f9fb;@orange-color: #fbae17;@purple-color: rgba(57, 26, 131, 1);@steel-color: rgba(117, 137, 141, 1);@light-blue-color: rgba(51, 156, 215, 1);