@import 'variables.less';
@import 'mixins.less';

.wizard-wrapper {
  .wizard-nav-bar {
    .flex-row();

    padding: 0.5rem;
    margin-bottom: 3rem;
    z-index: 2;

    background-color: @light-gray-color;
    border-radius: @border-radius-base;

    -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);

    button {
      > svg {
        margin-right: 8px;
      }

      &.icon-right {
        display: inline-flex;
        flex-direction: row-reverse;
        align-items: center;

        > svg {
          margin-right: 0;
          margin-left: 8px;
        }
      }
    }

    .wizard-title {
      flex: 1;

      h4 {
        margin: 0;
        text-align: center;
      }
    }
  }

  > .wizard-content-container {
    margin-bottom: 2rem;

    width: 960px;
    padding: 2rem;

    background-color: @light-gray-color;
    border-radius: @border-radius-base;

    -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);

    .ant-steps {
      .ant-steps-item {
        .ant-steps-item-container {
          .ant-steps-item-icon {
            background: @info-color;
            border-color: @info-color;
            color: #fff;
          }
        }

        &.ant-steps-item-wait {
          .ant-steps-item-container {
            .ant-steps-item-icon {
              background-color: #fff;
              border-color: rgba(0, 0, 0, 0.25);
            }
          }
        }
      }

      svg[data-icon='check'] {
        color: #fff;
      }
    }

    > .wizard-content {
      margin-top: 3rem;
    }
  }
}

@hd-screen-down: ~'(max-width:1280px)';@wide-screen-down: ~'(max-width:1199px)';@desktop-down: ~'(max-width:991px)';@tablet-down: ~'(max-width:767px)';@phone-down: ~'(max-width:480px)';@small-phone-down: ~'(max-width:320px)';@primary-color: rgba(19, 167, 61, 100);@info-color: rgba(41, 74, 147, 100);@danger-color: #a71d13;@layout-body-background: #fff;@border-radius-base: 4px;@light-gray-color: #f9f9fb;@orange-color: #fbae17;@purple-color: rgba(57, 26, 131, 1);@steel-color: rgba(117, 137, 141, 1);@light-blue-color: rgba(51, 156, 215, 1);