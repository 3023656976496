.flex-row() {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-column() {
  display: flex;
  flex-direction: column;
}

.flex-centered() {
  align-items: center;
  justify-content: center;
}