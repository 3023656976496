@import 'variables.less';
@import 'mixins.less';

#verify-phone {
  h5 {
    text-align: center;
    margin: 2;
  }
  width: 350px;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 15px;

  > .ant-form-item {
    margin-bottom: 0.6rem;

    //   .ant-input-prefix {
    //     margin-right: 6px;
    //     color: @info-color;
    //   }
    
  }

  .ant-input-affix-wrapper {
    padding: 2px 10px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
      Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }
  .ant-input-affix-wrapper > input.ant-input{
    font-size: 15px;
    color:#808080
  }
  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    padding: 5px;
  }
}

@hd-screen-down: ~'(max-width:1280px)';@wide-screen-down: ~'(max-width:1199px)';@desktop-down: ~'(max-width:991px)';@tablet-down: ~'(max-width:767px)';@phone-down: ~'(max-width:480px)';@small-phone-down: ~'(max-width:320px)';@primary-color: rgba(19, 167, 61, 100);@info-color: rgba(41, 74, 147, 100);@danger-color: #a71d13;@layout-body-background: #fff;@border-radius-base: 4px;@light-gray-color: #f9f9fb;@orange-color: #fbae17;@purple-color: rgba(57, 26, 131, 1);@steel-color: rgba(117, 137, 141, 1);@light-blue-color: rgba(51, 156, 215, 1);