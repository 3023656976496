@import 'variables.less';
@import 'mixins.less';

.list-editor-container {
  .flex-row();

  align-items: unset;
  flex-wrap: wrap;

  padding: 1rem 0 1rem 1rem;

  .editor-ex-item {
    &:nth-child(n + 4) {
      margin-top: 1rem;
    }
  }
}

// Half Flex css
.list-editor-container.half-flex {
  .editor-ex-item.scaffold {
    flex-basis: 100% !important;
  }

  .editor-ex-item {
    flex-basis: auto !important;
    margin: 3px;

    &:nth-child(n + 4) {
      margin-top: 0;
      margin-right: 3px;
    }
  }
}

.sortable-helper {
  z-index: 999;
}

@hd-screen-down: ~'(max-width:1280px)';@wide-screen-down: ~'(max-width:1199px)';@desktop-down: ~'(max-width:991px)';@tablet-down: ~'(max-width:767px)';@phone-down: ~'(max-width:480px)';@small-phone-down: ~'(max-width:320px)';@primary-color: rgba(19, 167, 61, 100);@info-color: rgba(41, 74, 147, 100);@danger-color: #a71d13;@layout-body-background: #fff;@border-radius-base: 4px;@light-gray-color: #f9f9fb;@orange-color: #fbae17;@purple-color: rgba(57, 26, 131, 1);@steel-color: rgba(117, 137, 141, 1);@light-blue-color: rgba(51, 156, 215, 1);