@import 'variables.less';
@import 'mixins.less';

.auth-container {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
  overflow: auto;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: ' ';
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: url(../../assets/images/splash.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  #signup-form,
  #login-form,
  #change-password-form,
  #change-email-form {
    h5 {
      text-align: center;
      margin: 2;
    }
    width: 350px;
    background-color: #ffffff;
    border-radius: 2px;
    padding: 25px;
    > .ant-form-item {
      margin-bottom: 0.5rem;

      .ant-input-prefix {
        margin-right: 6px;
        color: @info-color;
      }

      h5 {
        text-align: center;
        margin: 0;
      }

      &.login-button-container {
        margin-top: 1rem;

        .login-wrapper {
          .flex-row();
          .flex-centered();

          a {
            color: rgba(0, 0, 0, 0.85);
          }
        }
      }

      &.type-container {
        text-align: center;

        .ant-form-item-control-input {
          min-height: unset;
        }
      }

      .ant-divider-with-text {
        margin: 0;
      }

      .social-wrapper {
        .flex-row();
        .flex-centered();

        #facebook {
          color: #ffffff;
          background-color: #3b5998;
          border-color: #3b5998;

          span {
            margin-left: 6px;
          }
        }

        #google {
          color: #ffffff;
          background-color: #dd4b39;
          border-color: #dd4b39;

          span {
            margin-left: 6px;
          }
        }
      }

      .register-wrapper {
        .flex-row();
        .flex-centered();
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .code-wrapper .ant-form-item-has-feedback .ant-input {
      padding-right: 0;
    }
  }

  #signup-form {
    width: 400px;
  }
}

@hd-screen-down: ~'(max-width:1280px)';@wide-screen-down: ~'(max-width:1199px)';@desktop-down: ~'(max-width:991px)';@tablet-down: ~'(max-width:767px)';@phone-down: ~'(max-width:480px)';@small-phone-down: ~'(max-width:320px)';@primary-color: rgba(19, 167, 61, 100);@info-color: rgba(41, 74, 147, 100);@danger-color: #a71d13;@layout-body-background: #fff;@border-radius-base: 4px;@light-gray-color: #f9f9fb;@orange-color: #fbae17;@purple-color: rgba(57, 26, 131, 1);@steel-color: rgba(117, 137, 141, 1);@light-blue-color: rgba(51, 156, 215, 1);