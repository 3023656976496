.container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.box {
    width: 40%;
    height: auto;
    border: 1px solid black;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    line-height: 1.25;
    font-weight: 700;
    text-align: center;
    font-size: 34px;
    min-height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
}

.subTitle {
    text-align: center;
    color: rgb(112, 115, 124);
    font-size: 19px;
    line-height: 20px;
    font-weight: 500;
    margin-top: 4px;
}

.box img {
    max-width: 100%;
    max-height: 100%;
}


.buttonLite {
    background-color: #294A93;
    color: white;
    font-weight: bold;
    border-radius: 7px;
    font-size: 18px;
    border: none;
    font-weight: 500;
    height: 3rem;
    box-shadow: none;
}

.buttonLite:hover {
    background-color: #00bf78;
}

.buttonPremium {
    background-color: #FFA300;
    border: none;
    color: white;
    font-weight: bold;
    border-radius: 7px;
    font-size: 18px;
    font-weight: 500;
    height: 3rem;
    box-shadow: none;
}

.buttonPremium:hover {
    background-color: #2295fe;
}

.titledesc {
    margin-top: 20px;
    width: 90%;
    color: #19191b;
    font-size: 16px;
    font-weight: 400;
}

.boxWithLine {
    border: none;
    height: 2px;
    width: 97%;
    background-color: #e7e6e6;
    margin: 10px 0;
}

.bullet-list {
    font-size: 16px;
    line-height: 1.72em;
    letter-spacing: 0.01em;
    color: #19191b;
    margin-bottom: 0px;
    font-weight: 400;
    width: 96%;
}

.bullet-list ul {
    list-style-type: disc;
}

.no-bullet ul {
    list-style-type: none; 
  }

  .lastSubTitle{
    font-weight: 600;
    margin: 0;
    font-size: 1em;
    letter-spacing: -0.01em;
    font-size: 18;
    line-height: 1.2;
    color: #19191b;
  }
@hd-screen-down: ~'(max-width:1280px)';@wide-screen-down: ~'(max-width:1199px)';@desktop-down: ~'(max-width:991px)';@tablet-down: ~'(max-width:767px)';@phone-down: ~'(max-width:480px)';@small-phone-down: ~'(max-width:320px)';@primary-color: rgba(19, 167, 61, 100);@info-color: rgba(41, 74, 147, 100);@danger-color: #a71d13;@layout-body-background: #fff;@border-radius-base: 4px;@light-gray-color: #f9f9fb;@orange-color: #fbae17;@purple-color: rgba(57, 26, 131, 1);@steel-color: rgba(117, 137, 141, 1);@light-blue-color: rgba(51, 156, 215, 1);