@import 'variables.less';
@import 'mixins.less';

form {
  .ant-form-item {
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
.price-text {
  color: @info-color;
}

.card-element-wrapper {
  box-sizing: border-box;
  margin: 0;
  // padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  // display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
  min-height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover {
    border-color: #30b351;
    border-right-width: 1px !important;
  }

  &.focused {
    border-color: #30b351;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgb(19 167 61 / 20%);
  }

  &.error {
    border-color: #ff4d4f;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgb(19 167 61 / 20%);
  }
}
@hd-screen-down: ~'(max-width:1280px)';@wide-screen-down: ~'(max-width:1199px)';@desktop-down: ~'(max-width:991px)';@tablet-down: ~'(max-width:767px)';@phone-down: ~'(max-width:480px)';@small-phone-down: ~'(max-width:320px)';@primary-color: rgba(19, 167, 61, 100);@info-color: rgba(41, 74, 147, 100);@danger-color: #a71d13;@layout-body-background: #fff;@border-radius-base: 4px;@light-gray-color: #f9f9fb;@orange-color: #fbae17;@purple-color: rgba(57, 26, 131, 1);@steel-color: rgba(117, 137, 141, 1);@light-blue-color: rgba(51, 156, 215, 1);