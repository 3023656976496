@import 'variables.less';
@import 'mixins.less';

#add-job-wrapper,
#job-filled-questions,
#do-not-extend-job {
  .flex-column();
  align-items: center;
  min-height: 100vh;
  position: relative;
  background-color: #fff;
  padding-top: calc(513px * 0.25);

  z-index: 1;
  .ant-form {
    padding: 20px;
    width: 650px;
    background-color: #fff;
    .submit-button {
      display: flex;
      justify-content: flex-end;
    }
  }
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: ' ';
    width: 100%;
    height: 513px;
    z-index: -1;

    background-image: url(../../../../assets/images/sign-up/getting-started.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

@hd-screen-down: ~'(max-width:1280px)';@wide-screen-down: ~'(max-width:1199px)';@desktop-down: ~'(max-width:991px)';@tablet-down: ~'(max-width:767px)';@phone-down: ~'(max-width:480px)';@small-phone-down: ~'(max-width:320px)';@primary-color: rgba(19, 167, 61, 100);@info-color: rgba(41, 74, 147, 100);@danger-color: #a71d13;@layout-body-background: #fff;@border-radius-base: 4px;@light-gray-color: #f9f9fb;@orange-color: #fbae17;@purple-color: rgba(57, 26, 131, 1);@steel-color: rgba(117, 137, 141, 1);@light-blue-color: rgba(51, 156, 215, 1);